import React, { Component } from "react";
import { useState } from 'react';
import logo from './logo.svg';
import './App.scss';

export default function App() {
  const [toggle, setToggle] = useState(false);
  const [index, setIndex] = useState("");
  const images = [
    {
      filename: "/jake2.jpg",
      description: "Mr. Smiley V6, Calico Basin",
      position: ""
    },
    {
      filename: "/grass.jpg",
      description: "Desert Grasses, Oak Creek Canyon",
      position: ""
    },
    {
      filename: "/rando.jpg",
      description: "Have a Beer with Fear 5.11b, Calico",
      position: ""
    },
    {
      filename: "/flower.jpg",
      description: "Desert Flowers, Kraft Mountain",
      position: ""
    },
    {
      filename: "/jake3.jpg",
      description: "Astronomy V6, Oak Creek Canyon",
      position: "bottom"
    },
    {
      filename: "/soph.jpg",
      description: "Caustic 5.11b, Calico Basin",
      position: "bottom"
    },
    {
      filename: "/far.jpg",
      description: "Sandstone Quarry, Red Rocks",
      position: ""
    },
    {
      filename: "/friends.jpg",
      description: "Friends",
      position: ""
    },
    {
      filename: "/jake.jpg",
      description: "Unknown - California Coast",
      position: ""
    },
    {
      filename: "/muir6.jpg",
      description: "",
      position: ""
    },
    {
      filename: "/muir2.jpg",
      description: "",
      position: "bottom"
    },
    {
      filename: "/muir5.jpg",
      description: "",
      position: "bottom"
    },
    {
      filename: "/muir3.jpg",
      description: "",
      position: ""
    },
    {
      filename: "/muir4.jpg",
      description: "",
      position: ""
    },
    {
      filename: "/muir1.jpg",
      description: "",
      position: ""
    },
    {
      filename: "/bike.jpg",
      description: "Lone Tree, Pebble Beach",
      position: ""
    },
    {
      filename: "/bike2.jpg",
      description: "California Succulents",
      position: ""
    },
    {
      filename: "/bike6.jpg",
      description: "Marin Coast",
      position: "top"
    },
    {
      filename: "/bike8.jpg",
      description: "Hawk Hill, Marin Headlands",
      position: ""
    },
    {
      filename: "/bike3.jpg",
      description: "Pebble Beach Looking South",
      position: ""
    },
    {
      filename: "/bike7.jpg",
      description: "Hill 88, Marin Headlands",
      position: "right"
    },
    {
      filename: "/bike4.jpg",
      description: "Richmond Bridge from Paradise Drive",
      position: ""
    },
    {
      filename: "/bike5.jpg",
      description: "Near Ft. Cronkite",
      position: ""
    },
    {
      filename: "/bike9.jpg",
      description: "San Francisco from The Headlands",
      position: "top"
    },
    {
      filename: "/old/a.jpg",
      description: "Death Valley National Park",
      position: ""
    },
    {
      filename: "/old/p.jpg",
      description: "Death Valley National Park",
      position: ""
    },
    {
      filename: "/old/n.jpg",
      description: "Great Sand Dunes National Park",
      position: "top"
    },
    {
      filename: "/old/ac.jpg",
      description: "Mesa Arch, Canyonlands National Park",
      position: ""
    },
    {
      filename: "/old/q.jpg",
      description: "Delicate Arch, Arches National Park",
      position: ""
    },
    {
      filename: "/old/aa.jpg",
      description: "Death Valley National Park",
      position: ""
    },
    {
      filename: "/old/ab.jpg",
      description: "Storm Clouds - Eastern Sierra",
      position: ""
    },
    {
      filename: "/old/e.jpg",
      description: "San Francisco Harvest Moon",
      position: ""
    },
    {
      filename: "/old/l.jpg",
      description: "Lion's Head Sunrise - Cape Town",
      position: ""
    },
    {
      filename: "/old/u.jpg",
      description: "Eastern Sierras - Bishop, CA",
      position: ""
    },
    {
      filename: "/old/s.jpg",
      description: "Fishing - Point Reyes National Park",
      position: ""
    },
    {
      filename: "/old/r.jpg",
      description: "Eastern Sierras - Bishop, CA",
      position: ""
    },
    {
      filename: "/old/sa.jpg",
      description: "",
      position: "left"
    },
    {
      filename: "/old/sb.jpg",
      description: "",
      position: "right"
    },
    {
      filename: "/old/sc.jpg",
      description: "",
      position: ""
    },
    {
      filename: "/old/sd.jpg",
      description: "",
      position: "right"
    },
    {
      filename: "/old/se.jpg",
      description: "",
      position: ""
    },
    {
      filename: "/old/sf.jpg",
      description: "",
      position: "right"
    }
  ];

  function handleClick(e) {
    setToggle(!toggle);
    setIndex(e.target.getAttribute('data-index'));
  }

  function closePhotobox(e) {
    setToggle(false);
  }

  function nextPhoto(e) {
    setIndex((parseInt(index) + 1) % images.length);
  }

  function lastPhoto(e) {
    if(index == 0) {
      setIndex(images.length - 1);
    } else {
      setIndex((parseInt(index) - 1) % images.length);
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode === 39) {
      nextPhoto();
    } else if (e.keyCode === 37) {
      lastPhoto();
    } else if (e.keyCode === 27) {
      closePhotobox();
    }
  }

  return (
    <div tabIndex="0" onKeyDown={handleKeyDown}>
      {toggle ? <Photobox handleClick={handleClick} handleKeyDown={handleKeyDown} nextPhoto={nextPhoto} index={index} images={images} /> : ""}
      <div className="App container">
        <h1 className="header">Ian Civgin<span>Photography | Biking | Climbing | Surfing | Trail Running</span></h1>
        <p>I'm a photographer who loves to get outside and be active &mdash; whether that's climbing and surfing with friends, running around our local hills in Marin County, or biking somewhere new. I shoot with either a Canon 5D Mark III or a Sony A7CII.</p>
        <p>During the week, I lead the Product team at <a target="_blank" href="https://www.getpequity.com">Pequity</a>.</p>
        
        <br className="mobile" /><br />

        <div className="section-header">
          <h3>New Year, New Photos<span>January 2024</span></h3>
          <p>Hot off the press from Tennessee Valley.</p>
        </div>
        <div className="row">
          <div className="col">
            <div className="border"><img className="full" src="/tv1.jpg" /></div>
          </div>
        </div>

        <br className="mobile" /><br />

        <div className="section-header">
          <h3><a target="_blank" href="https://maps.app.goo.gl/GhGL3q4w3heyg11t5">Red Rock Canyon</a> - Climbing<span>November 2023</span></h3>
          <p>Photos from a climbing trip with friends to Red Rock Canyon outside Las Vegas, NV over Thanksgiving week.</p>
        </div>
        <div className="row">
          <Photo index={0} images={images} handleClick={handleClick} />
          <Photo index={1} images={images} handleClick={handleClick} />
          <Photo index={2} images={images} handleClick={handleClick} />
        </div>
        <div className="row">
          <Photo index={3} images={images} handleClick={handleClick} />
          <Photo index={4} images={images} handleClick={handleClick} />
          <Photo index={5} images={images} handleClick={handleClick} />
        </div>
        <div className="row">
          <Photo index={6} images={images} handleClick={handleClick} />
          <Photo index={7} images={images} handleClick={handleClick} />
          <Photo index={8} images={images} handleClick={handleClick} />
        </div>

        <br className="mobile" /><br />

        <div className="section-header">
          <h3><a target="_blank" href="https://maps.app.goo.gl/o2GcDrYHvYL6LmH37">Muir Woods National Monument</a><span>October 2023</span></h3>
          <p>A walkabout in the always-beautiful Muir Woods.</p>
        </div>
        <div className="row">
          <Photo index={9} images={images} handleClick={handleClick} />
          <Photo index={10} images={images} handleClick={handleClick} />
          <Photo index={11} images={images} handleClick={handleClick} />
        </div>
        <div className="row">
          <Photo index={12} images={images} handleClick={handleClick} />
          <Photo index={13} images={images} handleClick={handleClick} />
          <Photo index={14} images={images} handleClick={handleClick} />
        </div>

        <br className="mobile" /><br />

        <div className="section-header">
          <h3>Photos from the Bike<span>2023</span></h3>
          <p>My favorite photos captured while exploring on 2 wheels.</p>
        </div>
        <div className="row">
          <Photo index={15} images={images} handleClick={handleClick} />
          <Photo index={16} images={images} handleClick={handleClick} />
          <Photo index={17} images={images} handleClick={handleClick} />
        </div>
        <div className="row">
          <Photo index={18} images={images} handleClick={handleClick} />
          <Photo index={19} images={images} handleClick={handleClick} />
          <Photo index={20} images={images} handleClick={handleClick} />
        </div>
        <div className="row">
          <Photo index={21} images={images} handleClick={handleClick} />
          <Photo index={22} images={images} handleClick={handleClick} />
          <Photo index={23} images={images} handleClick={handleClick} />
        </div>

        <br className="mobile" /><br />

        <div className="section-header">
          <h3>Classics<span>2014-2022</span></h3>
          <p>Some of my favorite oldies.</p>
        </div>
        <div className="row">
          <Photo index={24} images={images} handleClick={handleClick} />
          <Photo index={25} images={images} handleClick={handleClick} />
          <Photo index={26} images={images} handleClick={handleClick} />
        </div>
        <div className="row">
          <Photo index={27} images={images} handleClick={handleClick} />
          <Photo index={28} images={images} handleClick={handleClick} />
          <Photo index={29} images={images} handleClick={handleClick} />
        </div>
        <div className="row">
          <Photo index={30} images={images} handleClick={handleClick} />
          <Photo index={31} images={images} handleClick={handleClick} />
          <Photo index={32} images={images} handleClick={handleClick} />
        </div>
        <div className="row">
          <Photo index={33} images={images} handleClick={handleClick} />
          <Photo index={34} images={images} handleClick={handleClick} />
          <Photo index={35} images={images} handleClick={handleClick} />
        </div>

        <br className="mobile" /><br />

        <div className="section-header">
          <h3>Surfing<span>2017-2019</span></h3>
          <p>California coast surfing adventures.</p>
        </div>
        <div className="row">
          <Photo index={36} images={images} handleClick={handleClick} />
          <Photo index={37} images={images} handleClick={handleClick} />
          <Photo index={38} images={images} handleClick={handleClick} />
        </div>
        <div className="row">
          <Photo index={39} images={images} handleClick={handleClick} />
          <Photo index={40} images={images} handleClick={handleClick} />
          <Photo index={41} images={images} handleClick={handleClick} />
        </div>

        <br className="mobile" /><br className="mobile" /><hr />
        <p className="copyright">© 2023 - Ian Civgin - All Rights Reserved</p>
        <br />
      </div>
    </div>
  );
}

function Photobox({handleClick, nextPhoto, images, index}) {
  return (
    <div className="photobox">
      <div className="background" onClick={handleClick}></div>
      <div className="photocontainer">
        <img onClick={nextPhoto} src={images[index].filename} />
        {images[index].description ? <p>{images[index].description}</p> : ""}
        {/*<span className="closer" onClick={handleClick}>X</span>*/}
      </div>
    </div>
  );
}

function Photo({handleClick, images, index}) {
  return(
    <div className="col-lg-4 col-md-12 img-square"><img src={images[index].filename} className={images[index].position} data-index={index} data-text={images[index].description} data-url={images[index].filename} onClick={handleClick} /><p>{images[index].description}</p></div>
  )
}
